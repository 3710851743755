.center-content {
  text-align: center;
}

.container-white {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.footer {
  font-size: 11px;
  color: gray;
  padding: 20px;
}

div.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 70px;
}

.caption {
  display: block;
  color: gray;
  font-size: 12px;
}
